import { Component, OnInit, ViewChild } from '@angular/core';
import { environment } from 'src/environments/environment';
import { RoutesService } from 'src/app/routes/routes.service';
import { AuthService } from 'src/app/auth/auth.service';
import { AppDeviceTypeService } from 'src/app/app.device-type.service';
import { FilterDimensions } from 'src/app/routes/dashboard-management/dashboard-management.service';
import { MainControllerService } from '../main-controller/main-controller.service';
import { UserPermissions } from 'src/app/auth/auth.interface';
import { NavigationBarExtrasComponent } from './extras/extras.component';

@Component({
	selector: 'navigation-bar',
	templateUrl: './navigation-bar.component.html',
	styleUrls: ['./navigation-bar.component.scss'],
	standalone: false
})
export class NavigationBarComponent implements OnInit {

	eUserPermissions = UserPermissions;
	eFilterDimensions = FilterDimensions;

	activatedRoute = this.routesService.getActivatedRoute();

	tokenInfo = this.authService.getTokenInfo();
	tokenData = this.authService.getTokenData();
	currentDevice;

	appVersion = environment.version;
	dateObj = new Date();

	filter = {
		tag: '',
		profitsCenter: '',
		sites: '',
		products: ''
	};

	sideNavBehavior = {
		sideNavBehaviorMinimized: false
	};

	@ViewChild(NavigationBarExtrasComponent, {static: true}) navbarExtra: NavigationBarExtrasComponent;

	constructor(
		private routesService: RoutesService,
		private authService: AuthService,
		private appDeviceTypeService: AppDeviceTypeService,
		private mainControllerService: MainControllerService,
	) {
		this.setCurrentDevice();
	}

	ngOnInit(){
		this.setMainControllerUserPreferences();

		this.mainControllerService.userPreferencesLoaded.subscribe(() => {
			this.setMainControllerUserPreferences();
		});
	}

	private setMainControllerUserPreferences() {
		const { sideNavBehaviorMinimized = null } = this.mainControllerService.userPreferences;
		this.sideNavBehavior.sideNavBehaviorMinimized = sideNavBehaviorMinimized;
	}

	private setCurrentDevice = () =>
		this.currentDevice = this.appDeviceTypeService.device;

	getRoute = name => this.routesService.getRoute(name);

	logout() {
		this.authService.logout(true, true);
		// .then(result => console.log('logout()', result));
		// .catch(error => console.log('logout()', error));
	}

	hideSideNav(){
		if(this.mainControllerService.getSidenav().mode === 'over'){
			this.mainControllerService.getSidenav().close();
		}
	}

	minimize(){
		this.mainControllerService.userPreferences.sideNavBehaviorMinimized =
			!this.mainControllerService.userPreferences.sideNavBehaviorMinimized;

		this.mainControllerService.postSideNavBehaviorOnPreferences();
	}
}
