import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
	selector: 'customer-logo',
	templateUrl: './customer-logo.component.html',
	styleUrls: ['./customer-logo.component.scss'],
	standalone: false
})
export class CustomerLogoComponent implements OnInit {

	customerLogoURL: string;

	whiteLabelProperties: WhiteLabelProperties;

	constructor(
		private authService: AuthService,
		private appService: AppService,
	) {
	}

	ngOnInit() {
		this.whiteLabelProperties = this.appService.getWhiteLabelProperties();
		this.setCustomerLogoURL();
	}

	private setCustomerLogoURL = () => {
		const logoApiURL = this.authService.getCustomerObj().LOGO_API_URL;
		if(logoApiURL){
			this.customerLogoURL = logoApiURL.replace(
				/get_enc_url\.ws/,
				'get_enc_svg.ws'
			);
		}else if(this.whiteLabelProperties?.logoBgDark){
			this.customerLogoURL = this.whiteLabelProperties?.logoBgDark;
		}
	};

}
