import * as i0 from '@angular/core';
import { Component, ChangeDetectionStrategy, NgModule } from '@angular/core';
import * as i1 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i4 from '@ngx-formly/core';
import { FormlyModule } from '@ngx-formly/core';
import * as i3 from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { FieldType, FormlyMatFormFieldModule } from '@ngx-formly/material/form-field';
import * as i2 from '@angular/material/input';
import { MatInputModule } from '@angular/material/input';
function FormlyFieldInput_input_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "input", 2);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("id", ctx_r0.id)("name", ctx_r0.field.name)("type", ctx_r0.type || "text")("readonly", ctx_r0.props.readonly)("required", ctx_r0.required)("errorStateMatcher", ctx_r0.errorStateMatcher)("formControl", ctx_r0.formControl)("formlyAttributes", ctx_r0.field)("tabIndex", ctx_r0.props.tabindex)("placeholder", ctx_r0.props.placeholder);
  }
}
function FormlyFieldInput_ng_template_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "input", 3);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("id", ctx_r0.id)("name", ctx_r0.field.name)("readonly", ctx_r0.props.readonly)("required", ctx_r0.required)("errorStateMatcher", ctx_r0.errorStateMatcher)("formControl", ctx_r0.formControl)("formlyAttributes", ctx_r0.field)("tabIndex", ctx_r0.props.tabindex)("placeholder", ctx_r0.props.placeholder);
  }
}
class FormlyFieldInput extends FieldType {
  get type() {
    return this.props.type || 'text';
  }
}
FormlyFieldInput.ɵfac = /* @__PURE__ */(() => {
  let ɵFormlyFieldInput_BaseFactory;
  return function FormlyFieldInput_Factory(__ngFactoryType__) {
    return (ɵFormlyFieldInput_BaseFactory || (ɵFormlyFieldInput_BaseFactory = i0.ɵɵgetInheritedFactory(FormlyFieldInput)))(__ngFactoryType__ || FormlyFieldInput);
  };
})();
FormlyFieldInput.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: FormlyFieldInput,
  selectors: [["formly-field-mat-input"]],
  standalone: false,
  features: [i0.ɵɵInheritDefinitionFeature],
  decls: 3,
  vars: 2,
  consts: [["numberTmp", ""], ["matInput", "", 3, "id", "name", "type", "readonly", "required", "errorStateMatcher", "formControl", "formlyAttributes", "tabIndex", "placeholder", 4, "ngIf", "ngIfElse"], ["matInput", "", 3, "id", "name", "type", "readonly", "required", "errorStateMatcher", "formControl", "formlyAttributes", "tabIndex", "placeholder"], ["matInput", "", "type", "number", 3, "id", "name", "readonly", "required", "errorStateMatcher", "formControl", "formlyAttributes", "tabIndex", "placeholder"]],
  template: function FormlyFieldInput_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵtemplate(0, FormlyFieldInput_input_0_Template, 1, 10, "input", 1)(1, FormlyFieldInput_ng_template_1_Template, 1, 9, "ng-template", null, 0, i0.ɵɵtemplateRefExtractor);
    }
    if (rf & 2) {
      const numberTmp_r2 = i0.ɵɵreference(2);
      i0.ɵɵproperty("ngIf", ctx.type !== "number")("ngIfElse", numberTmp_r2);
    }
  },
  dependencies: [i1.NgIf, i2.MatInput, i3.DefaultValueAccessor, i3.RequiredValidator, i3.NgControlStatus, i3.FormControlDirective, i4.ɵFormlyAttributes, i3.NumberValueAccessor],
  encapsulation: 2,
  changeDetection: 0
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FormlyFieldInput, [{
    type: Component,
    args: [{
      selector: 'formly-field-mat-input',
      template: `
    <input
      *ngIf="type !== 'number'; else numberTmp"
      matInput
      [id]="id"
      [name]="field.name"
      [type]="type || 'text'"
      [readonly]="props.readonly"
      [required]="required"
      [errorStateMatcher]="errorStateMatcher"
      [formControl]="formControl"
      [formlyAttributes]="field"
      [tabIndex]="props.tabindex"
      [placeholder]="props.placeholder"
    />
    <ng-template #numberTmp>
      <input
        matInput
        [id]="id"
        [name]="field.name"
        type="number"
        [readonly]="props.readonly"
        [required]="required"
        [errorStateMatcher]="errorStateMatcher"
        [formControl]="formControl"
        [formlyAttributes]="field"
        [tabIndex]="props.tabindex"
        [placeholder]="props.placeholder"
      />
    </ng-template>
  `,
      changeDetection: ChangeDetectionStrategy.OnPush
    }]
  }], null, null);
})();
class FormlyMatInputModule {}
FormlyMatInputModule.ɵfac = function FormlyMatInputModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || FormlyMatInputModule)();
};
FormlyMatInputModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: FormlyMatInputModule
});
FormlyMatInputModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[CommonModule, ReactiveFormsModule, MatInputModule, FormlyMatFormFieldModule, FormlyModule.forChild({
    types: [{
      name: 'input',
      component: FormlyFieldInput,
      wrappers: ['form-field']
    }, {
      name: 'string',
      extends: 'input'
    }, {
      name: 'number',
      extends: 'input',
      defaultOptions: {
        props: {
          type: 'number'
        }
      }
    }, {
      name: 'integer',
      extends: 'input',
      defaultOptions: {
        props: {
          type: 'number'
        }
      }
    }]
  })]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FormlyMatInputModule, [{
    type: NgModule,
    args: [{
      declarations: [FormlyFieldInput],
      imports: [CommonModule, ReactiveFormsModule, MatInputModule, FormlyMatFormFieldModule, FormlyModule.forChild({
        types: [{
          name: 'input',
          component: FormlyFieldInput,
          wrappers: ['form-field']
        }, {
          name: 'string',
          extends: 'input'
        }, {
          name: 'number',
          extends: 'input',
          defaultOptions: {
            props: {
              type: 'number'
            }
          }
        }, {
          name: 'integer',
          extends: 'input',
          defaultOptions: {
            props: {
              type: 'number'
            }
          }
        }]
      })]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { FormlyFieldInput, FormlyMatInputModule };
