import { AfterViewInit, Component, ViewChild, ViewContainerRef } from '@angular/core';
import { MainControllerService } from '../main-controller.service';

@Component({
	selector: 'mobile-toolbar-right-icons',
	templateUrl: './mobile-toolbar-right-icons.component.html',
	styleUrls: ['./mobile-toolbar-right-icons.component.scss'],
	standalone: false
})
export class MobileToolbarRightIconsComponent implements AfterViewInit {

	@ViewChild('wrapper', {read: ViewContainerRef}) viewContainerRef;

	constructor(
		private mainControlerService: MainControllerService
	) { }

	ngAfterViewInit(): void {
		this.mainControlerService.rightIconsRef = this.viewContainerRef;
		this.mainControlerService.refreshRightIcons();
	}

}
