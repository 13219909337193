import * as i0 from '@angular/core';
import { Component, ChangeDetectionStrategy, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import * as i4 from '@ngx-formly/core';
import { FormlyModule } from '@ngx-formly/core';
import * as i2 from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { FieldType, FormlyMatFormFieldModule } from '@ngx-formly/material/form-field';
import * as i1 from '@angular/material/input';
import { MAT_INPUT_VALUE_ACCESSOR, MatInputModule } from '@angular/material/input';
import * as i3 from '@angular/cdk/text-field';
class FormlyFieldTextArea extends FieldType {
  constructor() {
    super(...arguments);
    this.defaultOptions = {
      props: {
        cols: 1,
        rows: 1
      }
    };
  }
}
FormlyFieldTextArea.ɵfac = /* @__PURE__ */(() => {
  let ɵFormlyFieldTextArea_BaseFactory;
  return function FormlyFieldTextArea_Factory(__ngFactoryType__) {
    return (ɵFormlyFieldTextArea_BaseFactory || (ɵFormlyFieldTextArea_BaseFactory = i0.ɵɵgetInheritedFactory(FormlyFieldTextArea)))(__ngFactoryType__ || FormlyFieldTextArea);
  };
})();
FormlyFieldTextArea.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: FormlyFieldTextArea,
  selectors: [["formly-field-mat-textarea"]],
  standalone: false,
  features: [i0.ɵɵProvidersFeature([
  // fix for https://github.com/ngx-formly/ngx-formly/issues/1688
  // rely on formControl value instead of elementRef which return empty value in Firefox.
  {
    provide: MAT_INPUT_VALUE_ACCESSOR,
    useExisting: FormlyFieldTextArea
  }]), i0.ɵɵInheritDefinitionFeature],
  decls: 1,
  vars: 16,
  consts: [["matInput", "", 3, "id", "name", "readonly", "required", "formControl", "errorStateMatcher", "cols", "rows", "formlyAttributes", "placeholder", "tabindex", "cdkTextareaAutosize", "cdkAutosizeMinRows", "cdkAutosizeMaxRows"]],
  template: function FormlyFieldTextArea_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelement(0, "textarea", 0);
    }
    if (rf & 2) {
      i0.ɵɵclassProp("cdk-textarea-autosize", ctx.props.autosize);
      i0.ɵɵproperty("id", ctx.id)("name", ctx.field.name)("readonly", ctx.props.readonly)("required", ctx.required)("formControl", ctx.formControl)("errorStateMatcher", ctx.errorStateMatcher)("cols", ctx.props.cols)("rows", ctx.props.rows)("formlyAttributes", ctx.field)("placeholder", ctx.props.placeholder)("tabindex", ctx.props.tabindex)("cdkTextareaAutosize", ctx.props.autosize)("cdkAutosizeMinRows", ctx.props.autosizeMinRows)("cdkAutosizeMaxRows", ctx.props.autosizeMaxRows);
    }
  },
  dependencies: [i1.MatInput, i2.DefaultValueAccessor, i3.CdkTextareaAutosize, i2.RequiredValidator, i2.NgControlStatus, i2.FormControlDirective, i4.ɵFormlyAttributes],
  encapsulation: 2,
  changeDetection: 0
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FormlyFieldTextArea, [{
    type: Component,
    args: [{
      selector: 'formly-field-mat-textarea',
      template: `
    <textarea
      matInput
      [id]="id"
      [name]="field.name"
      [readonly]="props.readonly"
      [required]="required"
      [formControl]="formControl"
      [errorStateMatcher]="errorStateMatcher"
      [cols]="props.cols"
      [rows]="props.rows"
      [formlyAttributes]="field"
      [placeholder]="props.placeholder"
      [tabindex]="props.tabindex"
      [cdkTextareaAutosize]="props.autosize"
      [cdkAutosizeMinRows]="props.autosizeMinRows"
      [cdkAutosizeMaxRows]="props.autosizeMaxRows"
      [class.cdk-textarea-autosize]="props.autosize"
    ></textarea>
  `,
      providers: [
      // fix for https://github.com/ngx-formly/ngx-formly/issues/1688
      // rely on formControl value instead of elementRef which return empty value in Firefox.
      {
        provide: MAT_INPUT_VALUE_ACCESSOR,
        useExisting: FormlyFieldTextArea
      }],
      changeDetection: ChangeDetectionStrategy.OnPush
    }]
  }], null, null);
})();
class FormlyMatTextAreaModule {}
FormlyMatTextAreaModule.ɵfac = function FormlyMatTextAreaModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || FormlyMatTextAreaModule)();
};
FormlyMatTextAreaModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: FormlyMatTextAreaModule
});
FormlyMatTextAreaModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[CommonModule, ReactiveFormsModule, MatInputModule, FormlyMatFormFieldModule, FormlyModule.forChild({
    types: [{
      name: 'textarea',
      component: FormlyFieldTextArea,
      wrappers: ['form-field']
    }]
  })]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FormlyMatTextAreaModule, [{
    type: NgModule,
    args: [{
      declarations: [FormlyFieldTextArea],
      imports: [CommonModule, ReactiveFormsModule, MatInputModule, FormlyMatFormFieldModule, FormlyModule.forChild({
        types: [{
          name: 'textarea',
          component: FormlyFieldTextArea,
          wrappers: ['form-field']
        }]
      })]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { FormlyFieldTextArea, FormlyMatTextAreaModule };
