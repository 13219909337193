import * as i0 from '@angular/core';
import { Component, ChangeDetectionStrategy, ViewChildren, NgModule } from '@angular/core';
import * as i2 from '@angular/common';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import * as i3 from '@ngx-formly/core';
import { FormlyModule } from '@ngx-formly/core';
import * as i4 from '@ngx-formly/core/select';
import { FormlySelectModule } from '@ngx-formly/core/select';
import { FieldType, FormlyMatFormFieldModule } from '@ngx-formly/material/form-field';
import * as i1 from '@angular/material/checkbox';
import { MatCheckbox, MatCheckboxModule } from '@angular/material/checkbox';
function FormlyFieldMultiCheckbox_ng_container_0_Template(rf, ctx) {
  if (rf & 1) {
    const _r1 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵelementStart(1, "mat-checkbox", 1);
    i0.ɵɵlistener("change", function FormlyFieldMultiCheckbox_ng_container_0_Template_mat_checkbox_change_1_listener($event) {
      const option_r2 = i0.ɵɵrestoreView(_r1).$implicit;
      const ctx_r2 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r2.onChange(option_r2.value, $event.checked));
    });
    i0.ɵɵtext(2);
    i0.ɵɵelementEnd();
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const option_r2 = ctx.$implicit;
    const i_r4 = ctx.index;
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵproperty("id", ctx_r2.id + "_" + i_r4)("formlyAttributes", ctx_r2.field)("tabIndex", ctx_r2.props.tabindex)("color", ctx_r2.props.color)("labelPosition", ctx_r2.props.labelPosition)("checked", ctx_r2.isChecked(option_r2))("disabled", ctx_r2.formControl.disabled || option_r2.disabled);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1(" ", option_r2.label, " ");
  }
}
class FormlyFieldMultiCheckbox extends FieldType {
  constructor() {
    super(...arguments);
    this.defaultOptions = {
      props: {
        hideFieldUnderline: true,
        floatLabel: 'always',
        color: 'accent' // workaround for https://github.com/angular/components/issues/18465
      }
    };
  }
  onChange(value, checked) {
    this.formControl.markAsDirty();
    if (this.props.type === 'array') {
      this.formControl.patchValue(checked ? [...(this.formControl.value || []), value] : [...(this.formControl.value || [])].filter(o => o !== value));
    } else {
      this.formControl.patchValue({
        ...this.formControl.value,
        [value]: checked
      });
    }
    this.formControl.markAsTouched();
  }
  // TODO: find a solution to prevent scroll on focus
  onContainerClick() {}
  isChecked(option) {
    const value = this.formControl.value;
    return value && (this.props.type === 'array' ? value.indexOf(option.value) !== -1 : value[option.value]);
  }
}
FormlyFieldMultiCheckbox.ɵfac = /* @__PURE__ */(() => {
  let ɵFormlyFieldMultiCheckbox_BaseFactory;
  return function FormlyFieldMultiCheckbox_Factory(__ngFactoryType__) {
    return (ɵFormlyFieldMultiCheckbox_BaseFactory || (ɵFormlyFieldMultiCheckbox_BaseFactory = i0.ɵɵgetInheritedFactory(FormlyFieldMultiCheckbox)))(__ngFactoryType__ || FormlyFieldMultiCheckbox);
  };
})();
FormlyFieldMultiCheckbox.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: FormlyFieldMultiCheckbox,
  selectors: [["formly-field-mat-multicheckbox"]],
  viewQuery: function FormlyFieldMultiCheckbox_Query(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵviewQuery(MatCheckbox, 5);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.checkboxes = _t);
    }
  },
  hostVars: 1,
  hostBindings: function FormlyFieldMultiCheckbox_HostBindings(rf, ctx) {
    if (rf & 2) {
      i0.ɵɵhostProperty("id", ctx.id);
    }
  },
  standalone: false,
  features: [i0.ɵɵInheritDefinitionFeature],
  decls: 3,
  vars: 6,
  consts: [[4, "ngFor", "ngForOf"], [3, "change", "id", "formlyAttributes", "tabIndex", "color", "labelPosition", "checked", "disabled"]],
  template: function FormlyFieldMultiCheckbox_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵtemplate(0, FormlyFieldMultiCheckbox_ng_container_0_Template, 3, 8, "ng-container", 0);
      i0.ɵɵpipe(1, "formlySelectOptions");
      i0.ɵɵpipe(2, "async");
    }
    if (rf & 2) {
      i0.ɵɵproperty("ngForOf", i0.ɵɵpipeBind1(2, 4, i0.ɵɵpipeBind2(1, 1, ctx.props.options, ctx.field)));
    }
  },
  dependencies: [i1.MatCheckbox, i2.NgForOf, i3.ɵFormlyAttributes, i2.AsyncPipe, i4.FormlySelectOptionsPipe],
  encapsulation: 2,
  changeDetection: 0
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FormlyFieldMultiCheckbox, [{
    type: Component,
    args: [{
      selector: 'formly-field-mat-multicheckbox',
      template: `
    <ng-container *ngFor="let option of props.options | formlySelectOptions : field | async; let i = index">
      <mat-checkbox
        [id]="id + '_' + i"
        [formlyAttributes]="field"
        [tabIndex]="props.tabindex"
        [color]="props.color"
        [labelPosition]="props.labelPosition"
        [checked]="isChecked(option)"
        [disabled]="formControl.disabled || option.disabled"
        (change)="onChange(option.value, $event.checked)"
      >
        {{ option.label }}
      </mat-checkbox>
    </ng-container>
  `,
      changeDetection: ChangeDetectionStrategy.OnPush,
      host: {
        '[id]': 'id'
      }
    }]
  }], null, {
    checkboxes: [{
      type: ViewChildren,
      args: [MatCheckbox]
    }]
  });
})();
class FormlyMatMultiCheckboxModule {}
FormlyMatMultiCheckboxModule.ɵfac = function FormlyMatMultiCheckboxModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || FormlyMatMultiCheckboxModule)();
};
FormlyMatMultiCheckboxModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: FormlyMatMultiCheckboxModule
});
FormlyMatMultiCheckboxModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[CommonModule, ReactiveFormsModule, MatCheckboxModule, FormlyMatFormFieldModule, FormlySelectModule, FormlyModule.forChild({
    types: [{
      name: 'multicheckbox',
      component: FormlyFieldMultiCheckbox,
      wrappers: ['form-field']
    }]
  })]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FormlyMatMultiCheckboxModule, [{
    type: NgModule,
    args: [{
      declarations: [FormlyFieldMultiCheckbox],
      imports: [CommonModule, ReactiveFormsModule, MatCheckboxModule, FormlyMatFormFieldModule, FormlySelectModule, FormlyModule.forChild({
        types: [{
          name: 'multicheckbox',
          component: FormlyFieldMultiCheckbox,
          wrappers: ['form-field']
        }]
      })]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { FormlyFieldMultiCheckbox, FormlyMatMultiCheckboxModule };
