export function	compressImage(
	fileImage: File,
	resizingFactor: number = 1,
	quality: number = .6
): Promise<File> {

	// eslint-disable-next-line no-async-promise-executor
	return new Promise<File>( async (resolve, reject) => {

		const imgToCompress = document.createElement('img');
		imgToCompress.src = await fileToDataUri(fileImage);
		imgToCompress.addEventListener("load", () => {

			try {

				const canvas = document.createElement("canvas");
				const context = canvas.getContext("2d");

				const originalWidth = imgToCompress.width;
				const originalHeight = imgToCompress.height;

				const canvasWidth = originalWidth * resizingFactor;
				const canvasHeight = originalHeight * resizingFactor;

				canvas.width = canvasWidth;
				canvas.height = canvasHeight;

				// generate image canvas
				context.drawImage(
					imgToCompress,
					0,
					0,
					originalWidth * resizingFactor,
					originalHeight * resizingFactor
				);

				const timestamp = (new Date()).getTime();

				// compress image canvas
				canvas.toBlob(
					(blob) => {
						if (blob) {

							const fileToUpload = new File(
								[blob],
								`compressed-file-image${timestamp}.jpg`,
								{type: 'image/jpeg'}
							);

							resolve(fileToUpload);

						}
					},
					"image/jpeg",
					quality
				);

			}catch(error){

				reject(error);

			}


		});

	})

}

function fileToDataUri(field: File) {
	return new Promise<string>((resolve) => {
		const reader = new FileReader();
		reader.addEventListener("load", () => {
			resolve(reader.result as string);
		});
		reader.readAsDataURL(field);
	});
}
