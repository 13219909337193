import { Directive, ElementRef, HostListener, Input, OnInit} from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';
import { APItranslate } from 'src/app/helpers/api-translate.interface';

@Directive({
	selector: '[translate]',
	standalone: false
})
export class TranslateDirective implements OnInit {

	showRef = false;

	@Input() translate: APItranslate;

	constructor(
		private el: ElementRef,
		private authService: AuthService
	) { }

	ngOnInit(): void {
		this.el.nativeElement.setAttribute('translate-wrapper', '');
		this.showText();
	}

	private showText(){

		const text = this.translate.txt_value || this.translate.txt_ref;

		if(this.showRef){
			this.el.nativeElement.innerHTML = `
				${text}
				<span class="translate-ref">${this.translate.txt_ref}<span>
			`;
		}else{
			this.el.nativeElement.innerHTML = text;
		}

	}

	@HostListener('contextmenu', ['$event']) onContextMenu($event: PointerEvent) {
		if(
			(
				this.authService.getCustomerObj().ADMIN
				||
				this.authService.getCustomerObj().KEYUSER
			)
			&&
			$event.button === 2 && $event.ctrlKey
		){
			$event.stopPropagation();
			$event.preventDefault();
			this.showRef = !this.showRef;
			this.showText();
			console.log(
				'contextmenu',
				$event.button,
				$event.altKey,
				$event.ctrlKey,
				$event.shiftKey,
				$event.metaKey
			);
		}
	}

	// @HostListener('click', ['$event']) onClick($event: MouseEvent) {
	// 	if($event.shiftKey){
	// 		$event.preventDefault();
	// 		$event.stopPropagation();
	// 		this.showRef = !this.showRef;
	// 		this.showText();
	// }

	// @Input() setTranslate(translate: APItranslate){
	// 	console.log(translate);
	// };

}
