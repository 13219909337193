import { Component, OnInit, Attribute, Input } from '@angular/core';
import { trigger, transition, style, useAnimation, state } from '@angular/animations';
import { zoomIn, zoomOut } from 'ng-animate';
import { ThemePalette } from '@angular/material/core';
import { LoadingSpinnerDelegate, setDoneDelegate, setStatusDelegate } from './loading-spinner.interface';

const defaultSizes = {
	small: 32,
	medium: 60,
	large: 180
};

@Component({
	selector: 'loading-spinner',
	templateUrl: './loading-spinner.component.html',
	styleUrls: ['./loading-spinner.component.scss'],
	animations: [
		trigger('statusFade', [
			state('true', style({ opacity: 0 })),
			transition(':enter', [
				useAnimation(zoomIn, { params: { timing: .3, } }),
				useAnimation(zoomOut, { params: { timing: .3, delay: .3 } }),
			])
		])
	],
	standalone: false
})
export class LoadingSpinnerComponent implements OnInit {

	loadingSpinnerDiameter;
	resultStatus = false;
	resultStatusIcon: setDoneDelegate;
	resultStatusIconColor: ThemePalette;

	@Input() delegate: LoadingSpinnerDelegate = {
		done: () => ({})
	};

	constructor(
	@Attribute('size') size: any,
	) {

		if(defaultSizes[size]){
			this.loadingSpinnerDiameter = defaultSizes[size];
		}else{
			this.loadingSpinnerDiameter = size || 100;
		}

	}

	ngOnInit(): void {
		this.setDelegates();
		console.log(this.delegate);
	}

	statusAnimationDone(event){
		if(event.toState === true){
			this.delegate.done(this.resultStatusIcon);
			this.resultStatus = false;
			this.resultStatusIcon = null;
		}
	}

	private setStatus = (status: setStatusDelegate) => {
		this.resultStatus = true;
		if(status === 'ok'){
			this.resultStatusIcon = setDoneDelegate.done;
			this.resultStatusIconColor = 'accent';
		}else{
			this.resultStatusIcon = setDoneDelegate.close;
			this.resultStatusIconColor = 'warn';
		}
	};

	private setDelegates = () => {
		this.delegate.setStatus = this.setStatus;
	};

}
