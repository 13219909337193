import { Component, Input } from '@angular/core';

@Component({
	selector: 'sidenav-toolbar-serial-id',
	templateUrl: './sidenav-toolbar-serial-id.component.html',
	styleUrls: ['./sidenav-toolbar-serial-id.component.scss'],
	standalone: false
})
export class ServiceOrderPlanningSideNavToolbarSerialIdComponent {

	mobileSerialIdFontSize = 16;

	_serial: ToolbarSerial = null;
	@Input({required: true}) set serial(serial: ToolbarSerial){
		if(serial){
			this.mobileSerialIdFontSize = Math.round(
				17.5 -
				(serial.serialId.length * 0.115)
			);
		}
		this._serial = serial;
	};
	get serial(): ToolbarSerial{
		return this._serial;
	}

	@Input() showProduct = false;
	@Input() small = false;
	@Input() mobileSerialIdFontAutoSize = false;
	@Input() serialHasTicketOpenInfoOutOfOService = false;

	constructor() {

	}

}

export interface ToolbarSerial {
	classColor: string;
	serialId: string;
	brandDesc?: string;
	modelDesc?: string;
	siteDesc?: string;
	productDesc: string;
	isTmp?: 0 | 1;

	categoryPriceCode?: number;
	categoryPriceDesc?: string;
	categoryPriceId?: string;
};
