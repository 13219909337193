import { DatePipe, formatNumber } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, Inject, Input, LOCALE_ID, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormControl, ValidatorFn } from '@angular/forms';
import { MatMenu, MatMenuTrigger } from '@angular/material/menu';
import { dateParseStringPipe } from 'src/app/pipes/date-parse-string';

@Component({
	selector: 'datetime-picker',
	templateUrl: './datetime-picker.component.html',
	styleUrls: ['./datetime-picker.component.scss'],
	standalone: false
})

export class DatetimePickerComponent
implements OnInit {

	list = {
		listHours: Array.from({length: 24}).map(
			(item, index) => formatNumber(index, this.locale, '2.0')
		),
		listMinutes1: Array.from({length: 6}).map(
			(item, index) => formatNumber(index * 10, this.locale, '2.0')
		),
		listMinutes2: Array.from({length: 10}).map(
			(item, index) => index
		),
	};

	formControlPlaceHolder = new FormControl<string>(null);
	private _dateTimePickerMenu: MatMenu;

	get dateTimePickerMenu() {
		return this._dateTimePickerMenu;
	}

	private _dateTimePickerTrigger: MatMenuTrigger;
	get dateTimePickerTrigger(){
		return this._dateTimePickerTrigger;
	}
	@ViewChild('dateTimePickerTrigger') set dateTimePickerTrigger(dateTimePickerTrigger: MatMenuTrigger){
		if(dateTimePickerTrigger){
			this._dateTimePickerTrigger = dateTimePickerTrigger;
			this.dateTimePickerTrigger.menuOpened.subscribe(
				() => this.datePickerDefaultValueOrigin = new Date(this.datePickerDefaultValue)
			);
			this.dateTimePickerTrigger.menuClosed.subscribe(
				() => this.datePickerCancel(false)
			);
		}
	}

	dateOnChangeSelected: string = '';
	private datePickerDefaultValueOrigin: Date;
	timePickerValue: Date;
	private _datePickerDefaultValue: Date | null = new Date();
	get datePickerDefaultValue(): any {
		return this._datePickerDefaultValue;
	}
	@Input() set datePickerDefaultValue(date: Date | string){
		if(date){
			this._datePickerDefaultValue = new Date(date);
			this.timePickerValue = new Date(date);
			this.setFormControlPlaceHolderValue();
		}
	}

	@Input() set inlineTimePickerInitValue(time: string){
		if(!time) {
			return;
		}
		this.setInlineTimePickerValue(time);
	}

	// private _formControlConnectValidators: FormControl<string>;
	// get formControlConnectValidators(){
	// 	return this._formControlConnectValidators;
	// }
	@Input() set formControlConnectValidators(formControlConnectValidators: ValidatorFn){
		if(formControlConnectValidators){

			// this._formControlConnectValidators = formControlConnectValidators;

			this.formControlPlaceHolder.addValidators(formControlConnectValidators)
			this.formControlPlaceHolder.updateValueAndValidity();

			// formControlConnectValidators.valueChanges.subscribe(() => this.setDefaultDateValueFromFormControl());

			// this.setDefaultDateValueFromFormControl();

		}
	}

	@Input() inlineTimepicker = false;
	@Input() maskPlaceHolder: string;

	@Input() requiredInlineTimepicker: boolean = false;

	@Input() minCalendarDate: Date;
	@Input() minCalendarCurrentDate: boolean;
	@Input() maxCalendarDate: Date;
	@Input() maxCalendarCurrentDate: boolean;

	@Input() showResetTimePickerFormControlButton: boolean;

	@Input() disabled = false;

	@Output() datePickerDefaultValueChange = new EventEmitter<Date>();
	@Output() datePickerOnClose = new EventEmitter<boolean>();
	@Output() timePickerChange = new EventEmitter<Date>();

	@ViewChildren(MatMenuTrigger) private trigger: QueryList<MatMenuTrigger>;
	@ViewChild('timeMenuHours') private timeMenuHours: MatMenu;
	@ViewChild('timeMenuMinutes1') private timeMenuMinutes1: MatMenu;
	@ViewChild('timeMenuMinutes2') private timeMenuMinutes2: MatMenu;

	constructor(
		@Inject(LOCALE_ID) private locale: string,
		private cdr: ChangeDetectorRef,
		private datePipe: DatePipe,
		private dateParseString: dateParseStringPipe,
	) {
	}

	ngOnInit() {
		// this.dateTimeOnChange(
		// 	this.datePipe.transform(this.datePickerDefaultValue, 'HH:mm'),
		// 	this.datePickerDefaultValue
		// );

		this.formControlPlaceHolder.valueChanges.subscribe(
			() => setTimeout(() => this.formControlPlaceHolder.updateValueAndValidity({emitEvent: false}))
		);
	}

	setInlineTimePickerValue(timeValue: string) {
		const time = new Date();
		const timeValueSplit = timeValue.split(':');

		time.setHours(parseInt(timeValueSplit[0]))
		time.setMinutes(parseInt(timeValueSplit[1]))

		this.timePickerValue = time;
	}

	// private setDefaultDateValueFromFormControl(){
	// 	if(this.formControlDP){

	// 		if(!this.formControlDP.value){
	// 			this.datePickerDefaultValue = new Date();
	// 			return;
	// 		}

	// 		this.datePickerDefaultValue = new Date(
	// 			this.maskValue
	// 				? this.dateParseString.transform(this.formControlDP.value, this.maskValue)
	// 				: this.formControlDP.value
	// 		)

	// 		this.setFormControlPlaceHolderValue();

	// 	}
	// }

	datePickerApply() {

		if(!this.isDateTimeValid()){
			return;
		}

		// if(this.formControlDP){
		// 	this.formControlDP.setValue(
		// 		this.maskValue
		// 			? this.datePipe.transform(this.datePickerDefaultValue, this.maskValue)
		// 			: this.datePickerDefaultValue.toISOString(),
		// 		{emitEvent: true}
		// 	);
		// }

		this.setFormControlPlaceHolderValue();
		this.datePickerDefaultValueChange.emit(this.datePickerDefaultValue);
		this.datePickerDefaultValueOrigin = new Date(this.datePickerDefaultValue);
		this.dateTimePickerTrigger.closeMenu();

	}

	setFormControlPlaceHolderValue(){
		if(!this.datePickerDefaultValue){
			this.formControlPlaceHolder.setValue(null);
			this.datePickerDefaultValue = new Date();
			return;
		}
		this.formControlPlaceHolder.setValue(
			this.maskPlaceHolder
				? this.datePipe.transform(this.datePickerDefaultValue, this.maskPlaceHolder)
				: this.datePickerDefaultValue.toISOString()
		);
	}

	datePickerCancel(closeMenu = true) {
		if(closeMenu){
			this.dateTimePickerTrigger.closeMenu();
		}
		this.datePickerDefaultValue = new Date(this.datePickerDefaultValueOrigin);
	}

	reset() {
		this.datePickerDefaultValue = new Date();

		if(this.formControlPlaceHolder){
			this.formControlPlaceHolder.setValue(null);
		}

		// if(this.formControlDP){
		// 	this.formControlDP.setValue(null, {emitEvent: false});
		// }

		this.datePickerDefaultValueChange.emit(null);

	}

	resetTimePickerValue() {
		this.timePickerValue = null;
		this.timePickerChange.emit(this.timePickerValue);
	}

	datePickerOnChange($event: Date) {
		if(!this.timePickerValue){
			this.timePickerValue = new Date();
		}
		$event.setHours(this.timePickerValue.getHours());
		$event.setMinutes(this.timePickerValue.getMinutes());
		this.datePickerDefaultValue = new Date($event);
	}

	preventCloseMenu(event: MouseEvent){
		event.stopPropagation();
	}

	timePickerOnChange(event: MouseEvent, value: string, where: 'hour' | 'minute'){
		event.stopPropagation();

		if(!this.timePickerValue){
			this.timePickerValue = new Date();
			this.timePickerValue.setMinutes(parseInt('0', 10));
		}

		switch (where) {
		case 'hour':
			this.timePickerValue.setHours(parseInt(value, 10));
			break;

		case 'minute':
			this.timePickerValue.setMinutes(parseInt(value, 10));
			break;
		}

		this.timePickerValue = new Date(this.timePickerValue);
		this.datePickerDefaultValue.setHours(this.timePickerValue.getHours());
		this.datePickerDefaultValue.setMinutes(this.timePickerValue.getMinutes());

		this.trigger.forEach(trigger => {
			if(
				trigger.menu === this.timeMenuHours && trigger.menuOpen
				||
				trigger.menu === this.timeMenuMinutes1 && trigger.menuOpen
				||
				trigger.menu === this.timeMenuMinutes2 && trigger.menuOpen
			){
				trigger.closeMenu();
			}
		});

		this.timePickerChange.emit(this.timePickerValue);
	}

	// dateTimeOnChange(value: string, date: Date) {

	// 	this.dateOnChangeSelected = value;
	// 	const hour = value.split(':');

	// 	if(!date){
	// 		return;
	// 	}

	// 	date.setHours(parseInt(hour[0], 10));
	// 	date.setMinutes(parseInt(hour[1], 10));

	// 	this.cdr.detectChanges();

	// }

	isDateTimeValid() {
		let isValid = true;

		if(this.minCalendarCurrentDate) {
			this.minCalendarDate = new Date();
		}

		if(this.maxCalendarCurrentDate) {
			this.maxCalendarDate = new Date();
		}

		if(
			this.minCalendarDate && this.minCalendarDate > this.datePickerDefaultValue
			||
			this.maxCalendarDate && this.maxCalendarDate < this.datePickerDefaultValue
		) {
			isValid = false;
		}
		return isValid;
	}

}
