import { Injectable } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';

@Injectable({
	providedIn: 'root'
})
export class I18nService {

	private userDetectedLocale: string;

	private dateMask = '';

	constructor(
		private translocoService: TranslocoService
	) {
		this.userDetectedLocale = this.getUsersLocale('en-US');
		console.log(I18nService.name, translocoService.getAvailableLangs());
		console.log(I18nService.name, this.userDetectedLocale, this.getLocalStorage());
		this.updateLocale(this.getLocalStorage() || this.userDetectedLocale);
	}

	updateLocale(locale, saveLocalStorage = false) {

		console.log('updateLocale', locale, this.translocoService.getDefaultLang());

		if(this.translocoService.getAvailableLangs().includes(locale)){
			this.translocoService.setActiveLang(locale);
		}else{
			this.translocoService.setActiveLang(this.translocoService.getDefaultLang());
		}

		if(saveLocalStorage){
			this.setLocalStorage(locale);
		}

		console.log('updateLocale getActiveLang', this.translocoService.getActiveLang());

		this.setDateMask();

	}

	private getUsersLocale(defaultValue: string): string {
		if (typeof window === 'undefined' || typeof window.navigator === 'undefined') {
			return defaultValue;
		}
		const wn = window.navigator as any;
		let lang: string = wn.languages ? wn.languages[0] : defaultValue;
		lang = lang || wn.language || wn.browserLanguage || wn.userLanguage;
		return lang.substring(0, 2);
	}

	private setLocalStorage(locale): void {
		return localStorage.setItem('locale', locale);
	}

	private getLocalStorage(): string {
		return localStorage.getItem('locale');
	}

	private setDateMask(){

		const maskTypesByLetter = {
			day: 'd',
			month: 'M',
			year: 'Y'
		};

		const formatter = (new Intl.DateTimeFormat(this.translocoService.getActiveLang()))
			.formatToParts();

		console.log('setDateMask formatter', formatter);

		let mask = '';

		formatter.map(item => {
			switch (item.type) {
			case 'day':
				mask += item.value.replace(/./g,maskTypesByLetter.day);
				break;

			case 'month':
				mask += item.value.replace(/./g,maskTypesByLetter.month);
				break;

			case 'year':
				mask += item.value.replace(/./g,maskTypesByLetter.year);
				break;

			case 'literal':
				mask += item.value;
				break;

			default:
				break;
			}
		});

		this.dateMask = mask;
	}

	public getDateMask(){
		return this.dateMask;
	}

	get detectedLocale(): string
	{
		return this.userDetectedLocale;
	}

}
