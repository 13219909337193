import { Component, OnInit } from '@angular/core';
import { DashboardFilterLevels, DashboardService } from '../../dashboard-management.service';

@Component({
	selector: '	dashboard-filter-level',
	templateUrl: './filter-level.component.html',
	styleUrls: ['./filter-level.component.scss'],
	standalone: false
})
export class DashboardFilterLevelComponent implements OnInit {

	level: OptionValues;
	eOptionValues = OptionValues;

	constructor(
		private dashboardService: DashboardService
	) { }

	ngOnInit(){
		this.setLevelValue();
	}

	private setLevelValue(){
		this.level = this.dashboardService.model.P_OPERATIONAL === 1
			? OptionValues.operational
			: OptionValues.management;
	}

	levelChange(){
		this.dashboardService.applyFilter(
			{
				[DashboardFilterLevels.management]: this.level === OptionValues.management
					? 1 : 0,
				[DashboardFilterLevels.operational]: this.level === OptionValues.operational
					? 1 : 0
			},
			DashboardFilterComponentName.dashboardFilterLevel,
		);
	}

}

export enum OptionValues {
	management,
	operational
}

export enum DashboardFilterComponentName {
	dashboardFilterLevel = 'dashboardFilterLevel'
}
