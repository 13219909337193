import { HttpErrorResponse, HttpEventType, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AppHttpRequestService } from 'src/app/helpers/app-http-request';

import { ApiUrls } from 'src/api-urls/urls';

@Injectable({
	providedIn: 'root'
})
export class APIuploadAwsFileWebappService {

	constructor(
		private appHttpRequestService: AppHttpRequestService
	) {}

	private resetUploadFileData(uploadFileData: FileUploadData){
		uploadFileData.done = false;
		uploadFileData.fail = false;
		uploadFileData.progress = 0;
		uploadFileData.inProgress = false;
	}

	upload(
		uploadFileData: FileUploadData,
		attrs
	): Observable<any>{

		const uploadData = new FormData();

		this.resetUploadFileData(uploadFileData);

		uploadData.append('file', uploadFileData.file, uploadFileData.file.name);

		// [todo] remove mock server error
		if(/mock-error/.test(uploadFileData.file.name)){
			uploadData.append('test_error', '1');
		}

		return (this.appHttpRequestService.getRequest({
			method: 'post',
			url: ApiUrls.portal.uploadFile,
			// url: 'http://localhost:3000/api',
			payload: uploadData,
			extras: attrs.extras,
			reportProgress: true,
			observe: 'events'
		}) as Observable<any>)
			.pipe(
				map(event => {
					switch (event.type) {
					case HttpEventType.UploadProgress:
						uploadFileData.inProgress = true;
						uploadFileData.progress = Math.ceil(event.loaded * 100 / event.total);
						break;
					case HttpEventType.Response:
						const response = event as HttpResponse<APIuploadAwsFileWebappServicePOSTResponse>;
						uploadFileData.inProgress = false;
						uploadFileData.done = true;
						uploadFileData.fileURLTemp = response.body.fileUrl;
						return event;
					}
				}),
				catchError((error: HttpErrorResponse) => {
					uploadFileData.inProgress = false;
					uploadFileData.done = false;
					uploadFileData.fail = true;
					// return of(`${file.data.name} upload failed.`);
					throw error;
				})
			);

	}

}

export interface APIuploadAwsFileWebappServicePOSTResponse {
	fileUrl: string,
	save: string
}

export interface FileUploadData {
	done: boolean;
	fail: boolean;
	inProgress: boolean;
	progress: number;
	file: File;
	fileURLTemp: string;
	fileUploadSubscription: Subscription;
}
