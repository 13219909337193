import { Component, OnInit, ElementRef, ViewChild, Input, OnDestroy, Output, EventEmitter, ChangeDetectorRef, Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslocoService } from '@jsverse/transloco';
import { Subject, Subscription } from 'rxjs';
import { FileUploadData } from 'src/app/api-services/portal/upload-aws-file-webapp.service';
import { AwsFileUploadComponent } from 'src/app/components/aws-file-upload/aws-file-upload.component';

@Component({
	selector: 'media-recorder',
	templateUrl: './media-recorder.component.html',
	styleUrls: ['./media-recorder.component.scss'],
	standalone: false
})
export class NamoaMediaRecorder implements OnInit, OnDestroy {

	isRecording = false;
	isPlaying = false;
	showPromptUpload = false;
	showPromptButtonOK = false;

	fileToUpload: File;
	fileUploadDone = false;
	recorder: MediaRecorder;
	recordURL = null;
	dataRecord = [];
	recordedBlob: Blob;
	fileUploadSubscription: Subscription;

	fileUploadData: FileUploadData;

	recordAutoStopTimeout = null;

	@Input() recordingTimeSeconds = 10;
	@Input() mediaURL: string;
	@Input() mediaType: 'video' | 'audio' = 'video'
	@Input() allowRecord: boolean = true;
	@Input() fileName: string = `namoa-media-recorder-${new Date().getTime().toString()}`;
	@Input() fileUploadObservables: FileUploadObservables;
	@Input() awsFileUploadFormControl = new FormControl();
	@Input() disabled = false;

	@Output() onRecordFinish = new EventEmitter<Blob>();
	@Output() onStartFileUploadObservable = new EventEmitter<FileUploadObservables>();
	@Output() onFileUploadSubscriber = new EventEmitter<Subscription>();
	@Output() onRecordCancel = new EventEmitter<string>();

	@ViewChild('preview') preview: ElementRef<any>;
	@ViewChild('recording') recording: ElementRef<any>;
	@ViewChild('startButton') startButton: ElementRef<any>;
	@ViewChild('stopButton') stopButton: ElementRef<any>;
	@ViewChild('downloadButton') downloadButton: ElementRef<any>;
	@ViewChild('sfxendrecord') sfxendrecord: ElementRef<any>;

	@ViewChild('awsFileUpload') awsFileUpload: AwsFileUploadComponent;

	progressBarStyleObject = {
		'--recording-timer-size': '100%',
		'--recording-timer-duration': `${this.recordingTimeSeconds}s`
	};

	constructor(
		private matSnackBar: MatSnackBar,
		private cdr: ChangeDetectorRef,
		private namoaMediaRecorderService: NamoaMediaRecorderService,
		private translocoService: TranslocoService
	) {
	}

	ngOnInit(): void {
		this.checkMediaRecorderService();
		this.checkMediaURL();
	}

	mediaRecorderInUse(){
		return this.namoaMediaRecorderService.mediaRecorderInUse;
	}

	checkMediaURL(){
		if(this.mediaURL){
			this.recordURL = this.mediaURL;
		}
		if(this.recordURL){
			if(this.allowRecord){
				this.showPromptUpload = true;
				this.showPromptButtonOK = this.fileUploadData?.fail;
			}
			setTimeout(() => {
				this.recording.nativeElement.src = this.recordURL;
			});
		}
	}

	checkMediaRecorderService(){
		if(this.fileUploadObservables){
			this.setFileUploadSubscription(this.fileUploadObservables);
		}
	}

	log(msg) {
		console.log(`${msg}\n`);
	}

	wait(delayInMS) {
		return new Promise((resolve) => setTimeout(resolve, delayInMS));
	}

	setRecordTimer(stop = false){

		this.isRecording = !stop;

		setTimeout(() => {
			if(stop){
				this.progressBarStyleObject = {
					'--recording-timer-size': '100%',
					'--recording-timer-duration': `0s`
				};
			}else{
				this.progressBarStyleObject = {
					'--recording-timer-size': '0%',
					'--recording-timer-duration': `${this.recordingTimeSeconds}s`
				};
			}
			this.cdr.detectChanges();
		});

	}

	startRecording(stream, lengthInMS) {

		this.setRecordTimer();

		this.recorder = new MediaRecorder(stream, {
			mimeType: 'video/mp4',
			videoBitsPerSecond: 900000
		});

		clearTimeout(this.recordAutoStopTimeout);

		this.recording.nativeElement.src = null;
		this.recordURL = null;
		this.dataRecord = [];
		this.recordedBlob = null;

		this.recorder.ondataavailable = (event) => this.dataRecord.push(event.data);
		this.recorder.start();

		this.log(`${this.recorder.state} for ${lengthInMS / 1000} seconds…`);

		this.recording.nativeElement.addEventListener('play', event => {
			this.isPlaying = true;
		});

		this.recording.nativeElement.addEventListener('pause', event => {
			this.isPlaying = false;
		});

		this.recording.nativeElement.addEventListener('stop', event => {
			this.isPlaying = false;
		});

		this.recorder.addEventListener('stop', event => {

			this.namoaMediaRecorderService.mediaRecorderInUse = false;

			this.sfxendrecord.nativeElement.play();
			this.setRecordTimer(true);
			this.showPromptUpload = true;
			this.showPromptButtonOK = true;

			this.recordedBlob = new Blob(this.dataRecord, { type: "video/mp4" });
			this.recordURL = URL.createObjectURL(this.recordedBlob);
			this.recording.nativeElement.src = this.recordURL;
			this.preview.nativeElement.srcObject = null;

			this.onRecordFinish.emit(this.recordedBlob);

		})

		this.recorder.onerror = (event) => console.log(event);

		this.recordAutoStopTimeout = setTimeout(() => {
			if (this.recorder.state === "recording") {
				this.recorder.stop();
				this.stop(stream);
			}
		}, lengthInMS);

	}

	stop(stream: any) {
		if(stream){
			stream.getTracks().forEach((track) => track.stop());
		}
	}

	toogleRecordButton(){
		if(this.isRecording){
			this.stopButtonClick();
		}else{
			this.startButtonClick();
		}
		this.cdr.detectChanges();
	}

	stream: MediaStream;
	startButtonClick(){

		if(this.isRecording){
			return;
		}

		this.isRecording = true;
		this.namoaMediaRecorderService.mediaRecorderInUse = true;

		navigator.mediaDevices
			.getUserMedia({
				video: this.mediaType === 'audio'
					? false
					: ({
						facingMode: "environment",
						width: { min: 640, ideal: 960, max: 960 },
						height: { min: 480, ideal: 720, max: 720 },
					}),
				audio: true,
			})
			.then((stream) => {

				const videoTracks = stream
					.getVideoTracks();

				if(videoTracks.length){
					videoTracks[0]
						.applyConstraints({ advanced: [{ zoom: 2.25 }] } as any)
						.catch(error => console.log('zoom not supported', error));
				}

				this.stream = stream;

				this.preview.nativeElement.srcObject = stream;
				this.preview.nativeElement.muted = true;

				this.preview.nativeElement.captureStream =
					this.preview.nativeElement.captureStream || this.preview.nativeElement.mozCaptureStream;

				this.startRecording(stream, this.recordingTimeSeconds * 1000 + 50);

			})
			.catch((error) => {

				let snackMessage;

				this.isRecording = false;
				this.namoaMediaRecorderService.mediaRecorderInUse = false;

				if (error.name === "NotFoundError") {
					this.log("Camera or microphone not found. Can't record.");
					snackMessage = this.translocoService.translate('Components.Objects.MediaRecorder.NotFound');
				} else if(error.name === 'NotAllowedError') {
					snackMessage = this.translocoService.translate('Components.Objects.MediaRecorder.PermissionDenied');
				}else{
					this.log("Error");
					this.log(error);
				}

				this.matSnackBar.open(
					snackMessage,
					'OK',
					{
						panelClass: 'error',
						// duration: 3000,
					}
				);

			});
	}

	getRecordingTimerStyle(){
		if(this.isRecording){
			return {
				'--recording-timer-size': '0%',
				'--recording-timer-duration': `${this.recordingTimeSeconds}s`
			}
		}else{
			return {
				'--recording-timer-size': '100%',
				'--recording-timer-duration': `${this.recordingTimeSeconds}s`
			}
		}
	}

	uploadRecord(recordedBlob: Blob){

		if(this.fileToUpload){
			return;
		}

		this.fileToUpload = new File(
			[recordedBlob],
			`${this.fileName}.mp4`,
			{type: 'video/mp4'}
		);

		this.awsFileUpload.handleFile(
			this.fileToUpload,
			{
				payloadError: this.translocoService.translate('Components.Objects.MediaRecorder.PayloadError'),
				serverError: this.translocoService.translate('Components.Objects.MediaRecorder.ServerError')
			}
		).then(
			subs => {

				const subject = new Subject<FileUploadData>();

				const fileUploadObservable: FileUploadObservables = {
					data: {
						done: false,
						fail: false,
						inProgress: true,
						file: null,
						fileURLTemp: null,
						progress: 0,
						fileUploadSubscription: null
					},
					observable: subject
				};

				subs.subscribe({
					next: data => {
						fileUploadObservable.data = data;
						subject.next(data);
						this.cdr.detectChanges();
					},
					error: error => {
						subject.next(fileUploadObservable.data);
						this.fileToUpload = null;
						this.cdr.detectChanges();
					}
				})

				this.onStartFileUploadObservable.emit(fileUploadObservable);

				this.setFileUploadSubscription(fileUploadObservable);

			}
		);

		this.log(
			`Successfully recorded ${recordedBlob.size} bytes of ${recordedBlob.type} media.`
		);
	}

	setFileUploadSubscription(subs: FileUploadObservables){
		if(subs.data){
			this.setFileUploadSubscriptionOnResult(subs.data);
		}
		this.fileUploadSubscription = subs.observable.subscribe(
			result => this.setFileUploadSubscriptionOnResult(result)
		);
	}

	setFileUploadSubscriptionOnResult(result: FileUploadData){
		this.fileUploadData = result;
		if(result.done || result.fail){
			this.fileUploadSubscription = null;
			this.dataRecord = [];
			this.recordedBlob = result.file || null;
			this.showPromptButtonOK = result.fail;
			this.fileToUpload = null;
			this.fileUploadDone = true;
			this.mediaURL = result.fileURLTemp;
			this.checkMediaURL();
			if(this.allowRecord){
				this.showPromptUpload = true;
			}
		}
	}

	cancelRecord(){
		this.recordURL = null;
		this.recordedBlob = null;
		this.showPromptUpload = false;
		this.fileUploadDone = false;
		this.onRecordCancel.emit(this.fileName);
	}


	stopButtonClick(){
		this.stop(this.preview.nativeElement.srcObject);
		if(this.recorder){
			this.recorder.stop()
		}
	};

	ngOnDestroy(){

		this.namoaMediaRecorderService.mediaRecorderInUse = false;

		clearTimeout(this.recordAutoStopTimeout);
		this.stop(this.stream);

		if(this.fileUploadSubscription){
			this.fileUploadSubscription.unsubscribe();
		}

	}

}

export interface FileUploadObservables {
	data: FileUploadData,
	observable: Subject<FileUploadData>
}

@Injectable({providedIn: 'root'})

export class NamoaMediaRecorderService {
	private _mediaRecorderInUse = false;
	set mediaRecorderInUse(value: boolean){
		this._mediaRecorderInUse = value;
		this.mediaRecorderInUseChange.next(true);
	}
	get mediaRecorderInUse(){
		return this._mediaRecorderInUse;
	}
	mediaRecorderInUseChange = new Subject<boolean>();
}

