<audio
	#sfxendrecord
	playsinline
	controls
	src="/assets/sfx/service-bell-2.mp3"
	class="sfxendrecord"
></audio>

<div class="video-preview allow-record-{{allowRecord}}">

	@if(allowRecord){

		@if(isRecording){
			<div
				(click)="toogleRecordButton()"
				class="video-overlay-button button-stop-recording"
			></div>
			<div
				class="recording-timer"
				[ngStyle]="progressBarStyleObject"
			>
				<div class="progress-bar"></div>
			</div>
		}@else if(!isPlaying && !showPromptUpload){
			<button
				layout="column"
				flex
				mat-raised-button
				color="primary"
				class="button-record"
				(click)="toogleRecordButton()"
				[disabled]="mediaRecorderInUse() || disabled"
			>
				<div
					layout="row"
					layout-align="center center"
				>
					@if(mediaType === 'video'){
						<mat-icon svgIcon="video_camera_back"></mat-icon>
						<span *ngIf="mediaType === 'video'">{{"Components.Objects.MediaRecorder.RecordVideo" | transloco}}</span>
					}@else{
						<mat-icon svgIcon="mic"></mat-icon>
						<span *ngIf="mediaType === 'audio'">{{"Components.Objects.MediaRecorder.RecordAudio" | transloco}}</span>
					}
				</div>
			</button>
		}

		@if(!isPlaying && showPromptUpload){
			<div class="prompt-upload">
				<div
					(click)="cancelRecord()"
					class="video-overlay-button button-cancel"
				>
					<mat-icon svgIcon="close"></mat-icon>
				</div>
				@if(showPromptButtonOK){
					<div
						(click)="uploadRecord(recordedBlob)"
						class="video-overlay-button button-ok"
					>
						<mat-icon svgIcon="check"></mat-icon>
					</div>
				}
			</div>
		}

	}

	@if (this.mediaType === 'video') {
		<video
			#preview
			autoplay
			muted
			playsinline
			[ngStyle]="{display: !recordURL ? 'block' : 'none' }"
		></video>
		<video
			#recording
			playsinline
			controls
			[ngStyle]="{display: recordURL ? 'block' : 'none' }"
		></video>
	} @else if (this.mediaType === 'audio') {
		<audio
			#preview
			autoplay
			muted
			playsinline
			controls
			class="audio-preview"
			[ngStyle]="{display: !recordURL ? 'block' : 'none' }"
		></audio>
		<audio
			#recording
			playsinline
			controls
			class="audio-recording"
			[ngStyle]="{display: recordURL ? 'block' : 'none' }"
		></audio>
	}

	@if(fileUploadData && fileUploadData?.inProgress){
		<loading-spinner
			size="100"
			overlay-fill
		></loading-spinner>

		<div
			layout="row"
			layout-align="center center"
			class="fileUploadDataProgress"
		>
			<span no-padding>{{fileUploadData?.progress}}</span>
		</div>
	}

</div>

<aws-file-upload
	#awsFileUpload
	[awsFileUploadFormControl]="awsFileUploadFormControl"
	[setFormControlValidator]="true"
	fileNamePrefix="media-recorder-"
	[useOriginalFileName]="true"
	[labelAddLabel]="'Route.ServiceOrder.Components.Dialog.EDIProcessDialog.AddFile' | transloco"
	[labelChangeLabel]="'Route.ServiceOrder.Components.Dialog.EDIProcessDialog.ChangeFile' | transloco"
	[labelDeleteLabel]="'Route.ServiceOrder.Components.Dialog.EDIProcessDialog.DeleteFile' | transloco"
></aws-file-upload>
