import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform, Injectable } from '@angular/core';
import { I18nService } from '../i18n/i18n.service';
import { dateParseStringMaskTransform } from './date-parse-string.transform';

@Injectable({
	providedIn: 'root'
})
@Pipe({
	name: 'dateParseString',
	standalone: false
})
export class dateParseStringPipe implements PipeTransform {

	transform(dateString: string, mask?: string): string {
		return dateParseStringMaskTransform(dateString, mask);
	}
}

@Injectable({
	providedIn: 'root'
})
@Pipe({
	name: 'dateMaskI18n',
	standalone: false
})
export class dateMaskI18n implements PipeTransform {

	constructor(
		private datePipe: DatePipe,
		private i18nService: I18nService
	){

	}

	transform(dateString: string, mask?: string): string {
		return this.datePipe.transform(new Date(dateString), this.i18nService.getDateMask());
	}

}

@Injectable({
	providedIn: 'root'
})
@Pipe({
	name: 'datePipeNormalize',
	standalone: false
})
export class DatePipeNormalize implements PipeTransform {

	constructor(
		private datePipe: DatePipe
	){

	}

	transform(date: Date, mask?: string): string {
		return this.datePipe
			.transform(date, mask)
			.replace(/Z$/g, '+00:00');
	}

}
