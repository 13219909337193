import { Component, Input, OnInit } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';
import { TranslocoService } from '@jsverse/transloco';

@Component({
	selector: 'number-values-equalizer',
	templateUrl: './number-values-equalizer.component.html',
	styleUrls: ['./number-values-equalizer.component.scss'],
	standalone: false
})
export class NumberValuesEqualizerComponent implements OnInit {

	@Input({required: true}) value: number;
	@Input() isPriceValue: boolean = false;
	@Input() minIntegerDigits: number = 0;
	@Input() minDecimalPlaces: number = 0;
	@Input() decimalPlaces: number = null;

	activeLang: string;
	decimalPlacesEqualizer: string;

	constructor(
		public authService: AuthService,
		private translocoService: TranslocoService
	) {
	}

	ngOnInit(): void {
		this.activeLang = this.translocoService.getActiveLang();

		if(!this.isPriceValue) {
			this.decimalPlacesEqualizer = this.minIntegerDigits + '.' + this.minDecimalPlaces
			+ (this.decimalPlaces !== null ? '-' + this.decimalPlaces : '') ;
		}
		else {
			this.decimalPlacesEqualizer = `0.2-2`;
		}
	}
}
