import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl} from '@angular/forms';
import { NFormAPIDAtaTicketCreateValues } from 'src/app/api-services/portal/nform-nonuser.interface';

@Component({
	selector: 'ticket-open-info-serial',
	templateUrl: './ticket-open-info-serial.component.html',
	styleUrls: ['./ticket-open-info-serial.component.scss'],
	standalone: false
})
export class TicketOpenInfoSerialComponent implements OnInit {

	eNFormAPIDAtaTicketCreateValues = NFormAPIDAtaTicketCreateValues;

	@Input() translateResourceName: string = 'Route.Nform-nonuser';
	@Input() matChipStyle: boolean = false;
	@Input() isSerialStoppedLayout: 'column' | 'row' = 'row';
	@Input({required: true}) showDesiredDateFormControl: boolean = false;
	@Input({required: true}) desiredDateFormControl: FormControl;
	@Input({required: true}) showIsSerialStoppedFormControl: boolean = false;
	@Input({required: true}) isSerialStoppedFormControl: FormControl;
	@Input({required: true}) stoppedDateFormControl: FormControl;

	@Output() datesChange = new EventEmitter<boolean>();

	constructor(
		private datePipe: DatePipe
	) { }

	ngOnInit(){

		this.isSerialStoppedFormControl.valueChanges.subscribe(
			() => {

				if(this.isSerialStoppedFormControl.value == 1){
					this.stoppedDateFormControl.enable()
				}else{
					this.stoppedDateFormControl.disable()
				}

				if(this.isSerialStoppedFormControl.value === 0){

					const emitChange = !!this.stoppedDateFormControl.value;

					this.stoppedDateFormControl.setValue(null);

					if(emitChange){
						this.datesChange.emit(true);
					}

				}

			}
		);

	}

	setFormControlDateTypeValue(date: Date, formControl: FormControl){
		formControl.setValue(this.datePipe.transform(date, 'yyyy-MM-dd HH:mm:ss ZZZZZ'));
		this.datesChange.emit(true);
	}

}
