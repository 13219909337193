<div
	layout="column"
	layout-align="center"
	class="sidenav-toolbar-serial-id"
>
	<div
		layout="row"
		layout-align="start center"
	>
		<span
			*ngIf="serial.classColor"
			flex="none"
			class="badge"
			[ngStyle]="{
				'--badge-color': serial.classColor
			}"
		></span>
		<mat-icon
			*ngIf="serialHasTicketOpenInfoOutOfOService"
			svgIcon="road-stop-signal"
			class="icon-serial-out-of-service"
		></mat-icon>

		<mat-icon
			*ngIf="serial.isTmp"
			flex="none"
			svgIcon="schedule"
			class="icon-serial-is-temp"
		></mat-icon>

		<!-- [todo] remove mock (layout test) -->
		<!-- <div layout="row" style="gap: 16px;">
			<mat-icon
				svgIcon="history_toggle"
				class="icon-serial-out-of-service"
			></mat-icon>

			<mat-icon
				svgIcon="search_activity"
				class="icon-serial-out-of-service"
			></mat-icon>

			<mat-icon
				svgIcon="timelapse"
				class="icon-serial-out-of-service"
			></mat-icon>

			<mat-icon
				*ngIf="!!serialIsTmp"
				svgIcon="schedule"
				class="icon-serial-out-of-service"
			></mat-icon>

			<mat-icon
				svgIcon="timer"
				class="icon-serial-out-of-service"
			></mat-icon>
		</div> -->

		<span
			class="serial-id"
			[ngClass]="{
				small,
				'font-auto-size': mobileSerialIdFontAutoSize
			}"
			[ngStyle]="{
				'--serial-id-font-size': mobileSerialIdFontSize + 'px'
			}"
		>{{serial.serialId}}</span>
	</div>
	<div class="product-info">
		<span class="product-info-product">{{serial.productDesc}}</span>
		<span class="product-info-brand">{{serial.brandDesc}}</span>
		<span class="product-info-model">{{serial.modelDesc}}</span>
		<span class="product-info-site">{{serial.siteDesc}}</span>
		<span>{{serial.categoryPriceDesc}}</span>
	</div>
</div>
