import * as i0 from '@angular/core';
import { Component, ChangeDetectionStrategy, ViewChild, NgModule } from '@angular/core';
import * as i4 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i3 from '@ngx-formly/core';
import { ɵobserve, FormlyModule } from '@ngx-formly/core';
import * as i2 from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import * as i5 from '@ngx-formly/core/select';
import { FormlySelectModule } from '@ngx-formly/core/select';
import { FieldType, FormlyMatFormFieldModule } from '@ngx-formly/material/form-field';
import * as i1 from '@angular/material/radio';
import { MatRadioGroup, MatRadioModule } from '@angular/material/radio';
function FormlyFieldRadio_mat_radio_button_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "mat-radio-button", 2);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const option_r1 = ctx.$implicit;
    const i_r2 = ctx.index;
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵproperty("id", ctx_r2.id + "_" + i_r2)("color", ctx_r2.props.color)("labelPosition", ctx_r2.props.labelPosition)("disabled", option_r1.disabled)("value", option_r1.value);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1(" ", option_r1.label, " ");
  }
}
class FormlyFieldRadio extends FieldType {
  constructor() {
    super(...arguments);
    this.defaultOptions = {
      props: {
        hideFieldUnderline: true,
        floatLabel: 'always',
        tabindex: -1
      }
    };
  }
  ngAfterViewInit() {
    this.focusObserver = ɵobserve(this.field, ['focus'], ({
      currentValue
    }) => {
      if (this.props.tabindex === -1 && currentValue && this.radioGroup._radios.length > 0) {
        // https://github.com/ngx-formly/ngx-formly/issues/2498
        setTimeout(() => {
          const radio = this.radioGroup.selected ? this.radioGroup.selected : this.radioGroup._radios.first;
          radio.focus();
        });
      }
    });
  }
  // TODO: find a solution to prevent scroll on focus
  onContainerClick() {}
  ngOnDestroy() {
    super.ngOnDestroy();
    this.focusObserver && this.focusObserver.unsubscribe();
  }
}
FormlyFieldRadio.ɵfac = /* @__PURE__ */(() => {
  let ɵFormlyFieldRadio_BaseFactory;
  return function FormlyFieldRadio_Factory(__ngFactoryType__) {
    return (ɵFormlyFieldRadio_BaseFactory || (ɵFormlyFieldRadio_BaseFactory = i0.ɵɵgetInheritedFactory(FormlyFieldRadio)))(__ngFactoryType__ || FormlyFieldRadio);
  };
})();
FormlyFieldRadio.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: FormlyFieldRadio,
  selectors: [["formly-field-mat-radio"]],
  viewQuery: function FormlyFieldRadio_Query(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵviewQuery(MatRadioGroup, 7);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.radioGroup = _t.first);
    }
  },
  standalone: false,
  features: [i0.ɵɵInheritDefinitionFeature],
  decls: 4,
  vars: 10,
  consts: [[3, "formControl", "formlyAttributes", "required", "tabindex"], [3, "id", "color", "labelPosition", "disabled", "value", 4, "ngFor", "ngForOf"], [3, "id", "color", "labelPosition", "disabled", "value"]],
  template: function FormlyFieldRadio_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelementStart(0, "mat-radio-group", 0);
      i0.ɵɵtemplate(1, FormlyFieldRadio_mat_radio_button_1_Template, 2, 6, "mat-radio-button", 1);
      i0.ɵɵpipe(2, "formlySelectOptions");
      i0.ɵɵpipe(3, "async");
      i0.ɵɵelementEnd();
    }
    if (rf & 2) {
      i0.ɵɵproperty("formControl", ctx.formControl)("formlyAttributes", ctx.field)("required", ctx.required)("tabindex", ctx.props.tabindex);
      i0.ɵɵadvance();
      i0.ɵɵproperty("ngForOf", i0.ɵɵpipeBind1(3, 8, i0.ɵɵpipeBind2(2, 5, ctx.props.options, ctx.field)));
    }
  },
  dependencies: [i1.MatRadioButton, i1.MatRadioGroup, i2.NgControlStatus, i2.FormControlDirective, i3.ɵFormlyAttributes, i2.RequiredValidator, i4.NgForOf, i4.AsyncPipe, i5.FormlySelectOptionsPipe],
  encapsulation: 2,
  changeDetection: 0
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FormlyFieldRadio, [{
    type: Component,
    args: [{
      selector: 'formly-field-mat-radio',
      template: `
    <mat-radio-group
      [formControl]="formControl"
      [formlyAttributes]="field"
      [required]="required"
      [tabindex]="props.tabindex"
    >
      <mat-radio-button
        *ngFor="let option of props.options | formlySelectOptions : field | async; let i = index"
        [id]="id + '_' + i"
        [color]="props.color"
        [labelPosition]="props.labelPosition"
        [disabled]="option.disabled"
        [value]="option.value"
      >
        {{ option.label }}
      </mat-radio-button>
    </mat-radio-group>
  `,
      changeDetection: ChangeDetectionStrategy.OnPush
    }]
  }], null, {
    radioGroup: [{
      type: ViewChild,
      args: [MatRadioGroup, {
        static: true
      }]
    }]
  });
})();
class FormlyMatRadioModule {}
FormlyMatRadioModule.ɵfac = function FormlyMatRadioModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || FormlyMatRadioModule)();
};
FormlyMatRadioModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: FormlyMatRadioModule
});
FormlyMatRadioModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[CommonModule, ReactiveFormsModule, MatRadioModule, FormlyMatFormFieldModule, FormlySelectModule, FormlyModule.forChild({
    types: [{
      name: 'radio',
      component: FormlyFieldRadio,
      wrappers: ['form-field']
    }]
  })]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FormlyMatRadioModule, [{
    type: NgModule,
    args: [{
      declarations: [FormlyFieldRadio],
      imports: [CommonModule, ReactiveFormsModule, MatRadioModule, FormlyMatFormFieldModule, FormlySelectModule, FormlyModule.forChild({
        types: [{
          name: 'radio',
          component: FormlyFieldRadio,
          wrappers: ['form-field']
        }]
      })]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { FormlyFieldRadio, FormlyMatRadioModule };
